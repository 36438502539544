import * as React from 'react'
import { generatePath, useHref } from 'react-router-dom'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { publicRoutes } from '@constants/routes'
import { UrlString, VehicleId } from '@api/endpoints'
import { VehicleShort } from '@api/endpoints/vehicles'
import { formatNumberInEstonian, UserLanguageEnum } from '@model/language'
import vehicleDefaultImage from '@assets/images/vehicleDefaultImage.png'
import SvgFuelTypeIcon from '@assets/icons/fuelTypeIcon.svg'
import SvgGearboxTypeIcon from '@assets/icons/gearboxTypeIcon.svg'
import SvgDriveTypeIcon from '@assets/icons/driveTypeIcon.svg'
import { useLanguage } from '@hooks/useLanguage'
import { useEnumTranslation } from '@hooks/Translation/EnumTranslation'
import { Card, CardActionArea, CardContent, CardMedia, CardProps } from '@mui/material'
import { useFindTransmissionType, useFindWheelDrive } from '@hooks/Translation/TranslatedEntity'
import { useTranslateOptionalValue } from '@hooks/Translation/TranslatedValue'
import { CommonComponentProps } from '@controls/types'
import { CarprofCertified, VehiclePrice, VehicleMonthlyPaymentShort } from '@controls/panels'
import { VehicleSelectionMode } from '@pages/types'
import styles from './VehicleCard.module.css'

export type IconWithTextProps = React.PropsWithChildren &
  Readonly<{
    text: string
    className?: string
  }>

const IconWithText: React.FC<IconWithTextProps> = ({ children, text, className }) => (
  <div className={clsx(styles.iconWithText, className)}>
    {children}
    <div className={styles.iconWithText}>{text}</div>
  </div>
)

type VehicleCardProps = Readonly<{
  vehicle: VehicleShort
  selectionMode?: VehicleSelectionMode
  cardRef?: CardProps['ref']
}> &
  CommonComponentProps

const vehiclePageUrl = (
  vehicleId: VehicleId,
  language: UserLanguageEnum,
  selectionMode?: VehicleSelectionMode
): UrlString => {
  const baseUrl = generatePath(publicRoutes(language).VehiclePage, { vehicleId: vehicleId.toString() })
  return selectionMode ? `${baseUrl}?selectionMode=${selectionMode}` : baseUrl
}

export const VehicleCard: React.FC<VehicleCardProps> = ({ vehicle, selectionMode, cardRef, className, children }) => {
  const { t } = useTranslation()
  const { language } = useLanguage()
  const { fuelTypeTranslation } = useEnumTranslation()

  const vehicleName = vehicle.modelExtra
    ? `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.modelExtra}`
    : `${vehicle.year} ${vehicle.make} ${vehicle.model}`

  const translateOptionalValue = useTranslateOptionalValue()
  const transmissionType = translateOptionalValue(useFindTransmissionType(vehicle.transmission?.value))
  const fuelType = vehicle.fuel ? fuelTypeTranslation(vehicle.fuel).toLowerCase() : undefined
  const wheelDriveType = translateOptionalValue(useFindWheelDrive(vehicle.wheelDrive?.value))

  return (
    <Card ref={cardRef} className={clsx(styles.cardContainer, className)}>
      <CardActionArea href={useHref(vehiclePageUrl(vehicle.id, language, selectionMode))}>
        <CardMedia
          className={styles.cardMedia}
          component="img"
          image={vehicle.thumbnailImageUrl ?? vehicleDefaultImage}
          alt={`${vehicle.make} / ${vehicle.model}`}
          loading="lazy"
        />
        <CardContent className={clsx(styles.cardContent, styles.cardColumn)}>
          <div className={styles.cardColumn}>
            <h3 className={styles.vehicleName}>{vehicleName}</h3>
            <div className={clsx(styles.cardRow, styles.bottomLine)}>
              <span>
                {t('components.cards.vehicle.odometer-reading', {
                  odometerReading: vehicle.mileage ? formatNumberInEstonian(vehicle.mileage) : '-'
                })}
              </span>
              <CarprofCertified className={styles.cardCarprofCertified} />
            </div>
            <IconWithText text={transmissionType ?? '-'}>
              <img src={SvgGearboxTypeIcon} alt="gearbox type" />
            </IconWithText>
            <IconWithText text={fuelType ?? '-'}>
              <img src={SvgFuelTypeIcon} alt="fuel type" />
            </IconWithText>
            <IconWithText text={wheelDriveType ?? '-'}>
              <img src={SvgDriveTypeIcon} alt="wheel drive type" />
            </IconWithText>
          </div>
          <div className={clsx(styles.cardRow, styles.priceContainer)}>
            <VehiclePrice vehicle={vehicle} />
            <VehicleMonthlyPaymentShort vehicle={vehicle} />
          </div>
        </CardContent>
      </CardActionArea>
      {children}
    </Card>
  )
}
